.no-events {
  pointer-events: none;
}

.ml-neg-5 {
  margin-left: -5px;
}

.lds-dual-ring {
  margin: auto;
  display: inline-block;
  width: 80px;
  height: 80px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: $sdp-color-primary transparent $sdp-color-primary transparent;
  animation: lds-dual-ring 0.85s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
