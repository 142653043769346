@import 'variables';

/* Sweetalert reset styles */

.sdp-swal-title-cls {
  color: #333333 !important;
  font-family: $sdp-default-font-family !important;
  font-size: 1.625rem !important;
  font-weight: normal !important;
}

.swal2-html-container {
  color: #555555 !important;
  font-family: $sdp-default-font-family !important;
  font-size: 0.875rem !important;
  margin: 2rem 1.5rem 1rem !important;
}

.sdp-swal-action-cls {
  justify-content: flex-end !important;
  padding-right: 2rem !important;

  & > button.swal2-cancel {
    margin-right: 10px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius:2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eaeaea88; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #33333377; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #002c5698; 
}