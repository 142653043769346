@font-face {
  font-family: "Neue Haas Unica Pro";
  font-style: normal;
  font-weight: 400;
  src: url("./../../assets/fonts/Linotype - Neue Haas Unica Pro.otf")
    format("opentype");
}

@font-face {
  font-family: "Neue Haas Unica Pro";
  font-style: italic;
  font-weight: 400;
  src: url("./../../assets/fonts/Linotype - Neue Haas Unica Pro Italic.otf")
    format("opentype");
}

@font-face {
  font-family: "Neue Haas Unica Pro";
  font-style: normal;
  font-weight: 700;
  src: url("./../../assets/fonts/Linotype - Neue Haas Unica Pro Bold.otf")
    format("opentype");
}

@font-face {
  font-family: "Neue Haas Unica Pro";
  font-style: italic;
  font-weight: 700;
  src: url("./../../assets/fonts/Linotype - Neue Haas Unica Pro Bold Italic.otf")
    format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./../../assets/fonts/RobotoCondensed-Bold.ttf") format("truetype");
}
