@import "./variables";

$theme-colors: (
  "primary": $sdp-color-btn-primary-mb,
  "secondary": $sdp-color-btn-secondary
);

@import "./../../../node_modules/bootstrap/scss/functions";
@import "./../../../node_modules/bootstrap/scss/variables";
@import "./../../../node_modules/bootstrap/scss/variables-dark";
@import "./../../../node_modules/bootstrap/scss/maps";
@import "./../../../node_modules/bootstrap/scss/mixins";



@import "./../../../node_modules/bootstrap/scss/type";
@import "./../../../node_modules/bootstrap/scss/buttons";
@import "./../../../node_modules/bootstrap/scss/grid";

@import "./../../../node_modules/bootstrap/scss/root";



@import "./../../../node_modules/bootstrap/scss/reboot";


.btn-primary-mb {
  background-color: $sdp-color-btn-primary-mb !important;
  color: white !important;
}

.btn-outline-primary mr-3 {
  @include button-outline-variant(
    $sdp-color-btn-primary-mb,
    #222222,
    lighten($sdp-color-btn-primary-mb, 5%),
    $sdp-color-btn-primary-mb
  );
}

.btn-outline {
  color: #f8f9fa;
  border-color: #f8f9fa;
}


.btn-light{
  @include button-variant(
    #f8f9fa,
    darken($sdp-color-btn-primary-mb, 7.5%),
    darken($sdp-color-btn-primary-mb, 10%),
    lighten($sdp-color-btn-primary-mb, 5%),
    lighten($sdp-color-btn-primary-mb, 10%),
    darken($sdp-color-btn-primary-mb, 30%)
  );
}
.btn-outline-light{
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.bi-padding {
  height:24px;
  width:24px;
}

.btn-secondary {

  background-color:#198754 !important;;
  color: #ffffff !important;;
  @include button-variant(
    $sdp-color-btn-secondary,
    darken($sdp-color-btn-secondary, 7.5%),
    darken($sdp-color-btn-secondary, 10%),
    lighten($sdp-color-btn-secondary, 5%),
    lighten($sdp-color-btn-secondary, 10%),
    darken($sdp-color-btn-secondary, 30%)
  );
}

.btn-outline-primary mr-3 {
  @include button-outline-variant(
    $sdp-color-btn-secondary,
    #222222,
    lighten($sdp-color-btn-secondary, 5%),
    $sdp-color-btn-secondary
  );
}

.btn:disabled,
.btn.disabled {
  @include button-variant(grey, black);
}

@import "./../../../node_modules/bootstrap/scss/bootstrap";
