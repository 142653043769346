@import './fonts';
@import './variables';
@import './reset';
@import './common';
@import './helpers';
@import './overrides';
@import './bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~intl-tel-input/build/css/intlTelInput.css';
@import '~/node_modules/ngx-ui-switch/ui-switch.component.css';

