@import './variables';

html {
  height: 100vh;
  width: 100vw;
}

body {
  height: calc(100% - 29px);
  font-family: $sdp-default-font-family !important;
  background: #f2f2f2;
  overflow: hidden;
}

a {
  cursor: pointer;
}

.modal-dialog {
  max-width: 768px !important;
  width: fit-content !important;
  min-width: 500px !important;
}

@media (max-width: 576px) {
  .modal-dialog {
    min-width: auto !important;
    width: 96% !important;
  }
}
